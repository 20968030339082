<template>
  <div class="s-form-counter">
    <Field :name="name" :label="label" :error="error" :help="help" :help-class="helpClass">
      <div class="s-flex s-pt-3 s-pb-2">
        <button
          class="s-form-counter-decrease-btn"
          type="button"
          :disabled="!decreaseable || disabled"
          @click="decrease"
        >
          －
        </button>
        <input
          class="s-form-counter-label"
          type="text"
          :value="value"
          inputmode="numeric"
          pattern="\d*"
          ref="input"
          @change="handleInput"
          :disabled="disabled"
        >
        <button
          class="s-form-counter-increase-btn"
          type="button"
          :disabled="!increaseable || disabled"
          @click="increase"
        >
          ＋
        </button>
      </div>
    </Field>
  </div>
</template>

<script>
  import mixin from '@/mixins/liff/groupBuying/form'

  export default {
    mixins: [mixin],
    props: {
      min: {
        type: Number,
        default: 1,
      },
      max: {
        type: Number,
        default: 10,
      },
      step: {
        type: Number,
        default: 1,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      normalizedMin () {
        return this.min || 1
      },
      normalizedMax () {
        if (!this.max) {
          return 10
        }

        return this.max - (this.max - this.min) % this.step
      },
      valueAfterDecreasing () {
        return this.value - this.step
      },
      valueAfterIncreasing () {
        return this.value + this.step
      },
      decreaseable () {
        return this.valueAfterDecreasing >= this.normalizedMin
      },
      increaseable () {
        return this.valueAfterIncreasing <= this.normalizedMax
      },
    },
    methods: {
      decrease () {
        if (!this.decreaseable) {
          return
        }

        this.$emit('input', this.valueAfterDecreasing)
      },
      increase () {
        if (!this.increaseable) {
          return
        }

        this.$emit('input', this.valueAfterIncreasing)
      },
      handleInput (e) {
        const value = e.target.value

        this.$nextTick(() => {
          this.resetInput()

          if (!value) {
            return this.$emit('input', this.normalizedMin)
          }

          if (!value.match(/^\d+$/)) {
            return this.$emit('input', this.normalizedMin)
          }

          if (+value < this.normalizedMin) {
            return this.$emit('input', this.normalizedMin)
          }

          if (+value > this.normalizedMax) {
            return this.$emit('input', this.normalizedMax)
          }

          if ((+value - this.normalizedMin) % this.step !== 0) {
            return this.$emit('input', this.normalizedMin)
          }

          return this.$emit('input', +value)
        })
      },
      resetInput () {
        this.$refs.input.value = this.value
      },
    }
  }
</script>

<style scoped>
  .s-form-counter .s-form-counter-decrease-btn,
  .s-form-counter .s-form-counter-increase-btn,
  .s-form-counter .s-form-counter-label {
    padding: 0.25rem 2rem;
    border: solid 1px var(--s-secondary);
    text-align: center;
    font-size: var(--s-text-xl);
    line-height: var(--s-text-xl-line-height);
    background-color: transparent;
  }

  .s-form-counter .s-form-counter-decrease-btn,
  .s-form-counter .s-form-counter-increase-btn {
    color: var(--s-secondary);
    user-select: none;
  }

  .s-form-counter .s-form-counter-decrease-btn:disabled,
  .s-form-counter .s-form-counter-increase-btn:disabled {
    opacity: 0.4;
  }

  .s-form-counter .s-form-counter-decrease-btn {
    border-right: none;
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem;
  }

  .s-form-counter .s-form-counter-increase-btn {
    border-left: none;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .s-form-counter .s-form-counter-label {
    flex: 1 1 0;
    width: 100%;
  }
</style>
